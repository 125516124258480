<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          <number
          v-if="useAnimation"
          :from="numberFrom"
          :format="formatNumber"
          :to="numberTo"
          :duration="duration"
          easing="Power4.easeOut"
          @complete="completed"
        />
        <template v-if="!useAnimation">
            {{ formatNumber(numberTo) }}
        </template>
        </h2>
        <span>{{ title }}</span>
      </div>
      <b-avatar
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BAvatar, BCardBody } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BCardBody
  },
  props: {
    options: {
      type: Object,
      required: true,
      default: {
      },
    },

  },

  data()
  {
    const {value, 
      useAnimation, 
      prefix, 
      suffix, 
      decimals, 
      title, 
      color, 
      icon} = this.options;

    return {
      numberFrom: 0,
      numberTo: value,
      duration: 5,
      useAnimation: useAnimation,
      prefix: prefix,
      suffix: suffix,
      decimals: decimals,
      title: title,
      color: color,
      icon: icon
    };
  },

  mounted()
  {
   this.$emit('elementLoaded', event)
  },


  methods: {
    formatNumber(number)
    {
      return this.prefix + number.toFixed(this.decimals) + this.suffix;
    },

    completed()
    {
      console.log('Animation ends!');
      //console.log(this.numberFrom, this.numberTo)
    },

  }
};


</script>
